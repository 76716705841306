












import { Component, Vue, Prop } from 'vue-property-decorator'

interface SellingPointData {
  icon: string
  title: string
  subTitle: string
}

@Component
export default class SellingPoint extends Vue {
  @Prop({ required: true })
  point: SellingPointData;
}
