











import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DownloadBarData } from './DownloadBar.vue'

interface DownloadBarListData {
  title: string
  files: DownloadBarData
}

@Component
export default class DownloadBarList extends Vue {
  @Prop() data: DownloadBarListData
}
