import { Vue } from 'vue-property-decorator'
import 'reflect-metadata'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import router from './routes'
import VueBrowserUpdate from 'vue-browserupdate'
import App from './App.vue'
import '@/scss/index.scss'
import store from './store'
import { vEditable, vPermitted } from './directives'
import { i18n } from './translations'
import 'element-ui/lib/theme-chalk/display.css'
import { fFormatPrice } from './filters'
import 'country-flag-icons/1x1/flags.css'

Vue.config.productionTip = false

// Element UI: See guide on on-demand components for smaller build size
Vue.use(ElementUI, { locale })

// Show message on error
Vue.config.errorHandler = (err, vm, info) => {
  let error = err.toString()
  while (vm.$parent) {
    if (vm.$vnode.componentOptions.tag) {
      error = vm.$vnode.componentOptions.tag + ' -> ' + error
    }
    vm = vm.$parent
  }
  console.error(error)
  ElementUI.Message.error({
    message: `${err.message}`,
    duration: 6000,
  })
  throw new Error(error)
}

// Warns users with outdated browsers to update
Vue.use(VueBrowserUpdate, { options: { required: { e: -2, f: -6, o: -6, s: -1, c: -6 }, api: '2020.01' } })

Vue.directive('permitted', vPermitted)
Vue.directive('editable', vEditable)
Vue.filter('formatPrice', fFormatPrice)

// Make our components available anywhere
const requireComponent = require.context('./', true, /[A-Z]\w+\.vue$/)
requireComponent.keys().forEach(fileName => {
  const match = fileName.match(/(\w+)\.\w+$/)
  if (match) Vue.component(match[1], requireComponent(fileName).default)
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
