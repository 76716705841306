















































































import { EstimateCreateResult, GooseItemPrice, LineItem } from '@/types/signUpModels'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Component
export default class DealPayment extends Vue {
  @Getter deal: GooseItemPrice;
  @Getter estimate: EstimateCreateResult;
  @Getter couponValid?: boolean;
  @Getter currencyCode: string;
  @Getter couponCode: string;
  @Action addCouponCode;

  showCoupon = false;
  localCouponCode = null;

  get active(): boolean {
    return this.localCouponCode !== null && this.localCouponCode.length > 0 && this.couponCode !== this.localCouponCode
  }

  get lineItems(): LineItem[] {
    if (this.estimate == null) return []

    if (this.deal.price === 0) {
      return this.estimate.invoiceEstimate.lineItems
        .filter((item) => item.amount !== 0)
        .sort((a, b) => a.entityType === 'charge_item_price' ? -1 : 1)
    }
    return this.estimate.invoiceEstimate.lineItems
  }

  showCouponInput(): void {
    this.showCoupon = true
  }

  hasPricePerMonth(itemPriceId: string): boolean {
    return itemPriceId === this.deal.id || itemPriceId === this.deal.applicableItemPrices[0]?.id
  }

  getPricePerMonth(itemPriceId: string): number {
    const item = itemPriceId === this.deal.id ? this.deal : this.deal.applicableItemPrices[0]
    if (item.periodUnit === 'year') {
      return item.price / item.period / 12
    } else if (item.periodUnit === 'month') {
      return item.price
    }
  }
}
