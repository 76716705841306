

















import { Issuer, PaymentMethod } from '@/types/signUpModels'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
declare const Chargebee: any

@Component
export default class PaymentIDeal extends Vue {
  @Action signup;
  @Action createPaymentIntent;
  @Getter paymentMethods: PaymentMethod[];

  issuer: Issuer = null

  mounted(): void {
    this.issuer = this.paymentMethod.issuers[0]
  }

  get image(): string {
    return this.issuer?.image.svg
  }

  get paymentMethod(): PaymentMethod {
    return this.paymentMethods.find(p => p.id === 'ideal')
  }

  async onSubmit(): Promise<void> {
    try {
      const chargebeeInstance = Chargebee.getInstance()
      const intent = await chargebeeInstance.handlePayment('ideal', {
        paymentIntent: async () => {
          const data = await this.createPaymentIntent({ method: 'ideal' })
          return data.payment_intent
        },
        paymentInfo: {
          issuerBank: this.issuer.id,
        },
        redirectMode: true,
      })
    } catch (e) {
      // All kinds of errors are thrown here, log the error and show general message.
      console.warn('Error processing iDeal payment:', e)
      throw new Error(this.$t('signUp.iDealError').toString())
    }
  }
}
