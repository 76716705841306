

























































import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export default class DealPayment extends Vue {
  @Getter currencyCode;

  showIDeal = false;
  showCreditCard = false;
  showPayPal = false;

  toggleIDeal(): void {
    this.showCreditCard = false
    this.showPayPal = false
    this.showIDeal = !this.showIDeal
  }

  toggleCreditCard(): void {
    this.showPayPal = false
    this.showIDeal = false
    this.showCreditCard = !this.showCreditCard
  }

  togglePayPal(): void {
    this.showCreditCard = false
    this.showIDeal = false
    this.showPayPal = !this.showPayPal
  }
}
