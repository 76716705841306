






import { Vue } from 'vue-property-decorator'

export default class CyberAlarm extends Vue {
  getUrlwithQuery() {
    const baseUrl = process.env.VUE_APP_PORTAL + '/cyberalarm-app'
    return baseUrl + '?token=' + window.localStorage.token
  }
}
