






import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class IconSprite extends Vue {
  @Prop({ required: true })
  icon: string

  @Prop({ default: null })
  size: string

  @Prop({ default: null })
  rotate: string

  get href(): string {
    return `#${this.icon}`
  }

  get styles(): string {
    let s = ''
    if (this.size) s += `font-size:${this.size};`
    if (this.rotate) s += `transform:rotate(${this.rotate});`
    return s
  }
}

