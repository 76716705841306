






import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

@Component
export default class SignUp extends Vue {
  @Action signup;

  async mounted(): Promise<void> {
    const signupToken = await this.signup({ method: 'paypal', data: this.$route.query.token })
    this.$router.push('/set-password?token=' + signupToken)
  }
}
