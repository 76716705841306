










import { Component, Vue } from 'vue-property-decorator'
import { Language, languages } from '../translations'

@Component
export default class LanguageDropdown extends Vue {
  get languages(): Language[] {
    return languages
  }

  get flag(): string {
    return languages.find(l => l.locale === this.$i18n.locale).flag
  }

  changeLanguage(cc: string): void {
    localStorage.setItem('language', cc)
    this.$i18n.locale = cc
  }
}
