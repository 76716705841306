















































import { UserResponse } from '@/types/userModels'
import _, { cloneDeep } from 'lodash'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

interface Language {
  locale: string
  language: string
}

@Component
export default class MyPreferencesCard extends Vue {
  @Getter user: UserResponse;
  @Action updateUserSettings;

  userLanguages: Language[] = [
    {
      locale: 'en',
      language: 'English',
    },
    {
      locale: 'nl',
      language: 'Nederlands',
    },
    {
      locale: 'fr',
      language: 'Français',
    },
    {
      locale: 'de',
      language: 'Deutsch',
    },
  ]

  localUser = null;

  created(): void {
    this.setLocalUser()
  }

  @Watch('user', { deep: true })
  setLocalUser(): void {
    this.localUser = cloneDeep(this.user)
  }

  get disabled(): boolean {
    return _.isEqual(this.localUser, this.user)
  }

  get currentLanguage(): string {
    return this.userLanguages.find(l => l.locale === this.localUser.language).language
  }

  changeLanguage(language: string): void {
    this.localUser.language = language
  }

  async submit(): Promise<void> {
    await this.updateUserSettings(this.localUser)
  }
}
