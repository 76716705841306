import store from './store/index'
import axios from 'axios'

type RequestMethod = 'GET' | 'POST' | 'DELETE' | 'PUT'

export async function request(
  method: RequestMethod,
  path: string,
  data: Record<string, any> = null,
  headers: Record<string, any> = {},
) {
  const url = `${process.env.VUE_APP_API_URL}${path}`
  const token = store.getters.token
  headers.accept ??= 'application/json'
  headers['content-type'] ??= 'application/json'
  if (token) {
    headers.Authorization = `Bearer ${token}`
  }
  return axios({
    method,
    url,
    data,
    headers,
  })
}
