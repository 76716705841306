import { request } from '@/api'
import { gaLogin, gaSetUserId } from '@/ga'
import router from '@/routes'
import { PlanResponse, UserResponse } from '@/types/userModels'
import { getCamelCase, parseUserResponse } from '@/utils'
import { i18n } from '@/translations'

export default {
  state: () => {
    return {
      token: localStorage.getItem('token'),
      user: null,
      subscription: null,
      subscriptionIds: [],
      updateEmailError: false,
      loginLoading: false,
      userPaymentMethods: null,
    }
  },

  getters: {
    token(state): string {
      return state.token
    },
    user(state): { email: string } {
      return state.user
    },
    subscription(state): { email: string } {
      return state.subscription
    },
    subscriptionIds(state): string[] {
      return state.subscriptionIds
    },
    microsoftLoginUrl(): string {
      const appUrl = encodeURIComponent(process.env.VUE_APP_URL)
      return `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=143b4961-5755-461a-8dd4-4ffbd917a8da&scope=openid%20email&response_type=code%20id_token&redirect_uri=${appUrl}%2Fmicrosoft-login%2F&nonce=93213&code_challenge=8l-5ou6cOC2YvNpMl6Er54vSrR2zcUvnZVOio-MYQBo&code_challenge_method=S256`
    },
    updateEmailError(state): string {
      return state.updateEmailError
    },
    activeSubscription(state): boolean {
      return state.subscription.status === 'Active'
    },
    loginLoading(state): boolean {
      return state.loginLoading
    },
    userPaymentMethods(state): boolean {
      return state.userPaymentMethods
    },
    hasValidPaymentMethod(state): boolean {
      return !!state.userPaymentMethods?.find(p => p.payment_source.status === 'valid')
    },
  },

  mutations: {
    setToken(state, value): void {
      state.token = value
      if (value == null) {
        localStorage.removeItem('token')
      } else {
        localStorage.setItem('token', value)
      }
    },
    setUser(state, value: UserResponse): void {
      state.user = value
    },
    setSubscription(state, value: string): void {
      state.subscription = value
    },
    setSubscriptionIds(state, value: string[]): void {
      state.subscriptionIds = value
    },
    setUpdateEmailError(state, value: string): void {
      state.updateEmailError = value
    },
    setLoginLoading(state, value: string): void {
      state.loginLoading = value
    },
    setUserPaymentMethods(state, value: string): void {
      state.userPaymentMethods = value
    },
  },

  actions: {
    async exchangeMicrosoftToken({ commit, state }, token): Promise<void> {
      const { data } = await request('POST', '/auth/microsoft', { identity_token: token })
      gaLogin('microsoft')
      commit('setToken', data.token)
    },

    async login({ commit, state }, { email, password }): Promise<void> {
      commit('setLoginLoading', true)
      try {
        const { data } = await request('POST', '/auth/email', { email, password })
        gaLogin('email')
        commit('setToken', data.token)
      } finally {
        commit('setLoginLoading', false)
      }
    },

    async logout({ commit, state }): Promise<void> {
      commit('setToken', null)
      commit('setUser', null)

      gaSetUserId()
    },

    async forgotPassword(_, email): Promise<void> {
      await request('POST', '/auth/reset_password', { email })
    },

    async setPassword({ commit }, { token, password }): Promise<void> {
      const { data, status } = await request('POST', '/set-password', { token, password })
      commit('setToken', data.token)
      if (status === 200) {
        commit('successMessage', i18n.t('profile.passwordSetSuccess'))
      }
    },

    async fetchUser({ commit, state, dispatch }): Promise<void> {
      try {
        const userData = await request('GET', '/users/me')
        const user = parseUserResponse(userData.data)

        const subscriptionData = await request('GET', '/users/me/plan')
        const subscription = getCamelCase<PlanResponse>(subscriptionData.data)

        gaSetUserId(user.id)

        commit('setUser', user)
        commit('setSubscription', subscription)

        const subscriptionIds = subscription.name.split(', ')
        commit('setSubscriptionIds', subscriptionIds)
      } catch (error: any) {
        // If user is not found, log out
        const errorCode = error.response.data.code ?? null
        if (errorCode === 'E2001') {
          dispatch('logout')
          router.push('/login') // We should not use the router in the store, this is the only exception until general error handling is implemented
        } else {
          throw error
        }
      }
    },

    async updateUserSettings({ commit, state }, user: UserResponse): Promise<void> {
      const { data } = await request('PUT', '/users/me', {
        language: user.language,
        cyber_alarm_letter: user.cyberAlarmLetter ? 1 : 0,
        marketing_letter: user.marketingLetter ? 1 : 0,
        news_letter: user.newsLetter ? 1 : 0,
      })

      const updatedUser = parseUserResponse(data)

      commit('setUser', updatedUser)
    },

    async updateEmail({ commit, state, dispatch }, email: string): Promise<void> {
      const params = new URLSearchParams([['email', email]])
      const data = await request('GET', `/email-exists?${params}`)
      const { result } = getCamelCase<{ result: number }>(data.data)

      if (result) {
        commit('setUpdateEmailError', true)
        return null
      }

      await request('PUT', '/users/me/email', {
        email: email,
      })
      dispatch('fetchUser')
    },

    async updatePassword({ commit, state }, { currentPassword, newPassword }): Promise<void> {
      const res = await request('PUT', '/users/me/password', {
        current_password: currentPassword,
        new_password: newPassword,
      })
      if (res.status === 200) {
        commit('successMessage', i18n.t('profile.passwordUpdateSuccess'))
      }
    },

    async fetchUserPaymentMethods({ commit, state }): Promise<void> {
      const result = await request('GET', '/users/me/payment-methods')
      commit('setUserPaymentMethods', result.data)
    },

    async extendSubscriptionActivationCode({ commit, state, dispatch }, { code }): Promise<void> {
      const res = await request('POST', '/activation_codes/extend', {
        code,
      })
      return res.data
    },

    async upgradeLifetimeChargebeeSubscription({ commit, state, dispatch }, { itemPrices }): Promise<void> {
      const res = await request('POST', '/upgrade-lifetime-chargebee-subscription', {
        item_price_id: itemPrices,
      })
      return res.data
    },
  },
}
