







import { Component, Vue } from 'vue-property-decorator'
import { Language, languages } from '../translations'

@Component
export default class App extends Vue {
  get languages(): Language[] {
    return languages
  }

  changeLanguage(cc: string): void {
    localStorage.setItem('language', cc)
    this.$i18n.locale = cc
  }
}
