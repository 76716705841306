

























import { getPasswordCheck, isDisabled } from '@/utils'
import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

@Component
export default class MyPasswordCard extends Vue {
  @Action updatePassword

  currentPassword = ''
  newPassword = ''
  verifyPassword = ''
  hidePassword = true

  get disabled(): boolean {
    return isDisabled(this.newPassword, this.verifyPassword)
  }

  get passwordInfo(): string {
    const check = getPasswordCheck(this.newPassword, this.verifyPassword)
    if (check === null) return ''

    if (check) return this.$t('profile.passwordVerify').toString()
    return this.$t('profile.passwordInfo').toString()
  }

  async submit(): Promise<void> {
    await this.updatePassword({ currentPassword: this.currentPassword, newPassword: this.newPassword })
    this.currentPassword = ''
    this.newPassword = ''
    this.verifyPassword = ''
  }
}
