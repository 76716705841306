





































import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { CardComponent, CardNumber, CardExpiry, CardCvv } from '@chargebee/chargebee-js-vue-wrapper'

@Component({
  components: {
    CardComponent,
    CardNumber,
    CardExpiry,
    CardCvv,
  },
})
export default class PaymentCC extends Vue {
  @Action createPaymentIntent;
  @Action signup;

  styles = {
    base: {
      color: '#011936',
      fontWeight: '300',
      fontFamily: '\'Manrope\', sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':focus': {
        // Needed so that the focus color changes
      },
      '::placeholder': {
        color: '#9293AB',
        fontSize: '14px',
      },
      ':focus::placeholder': {
        color: '#666',
      },
    },
    invalid: {
      color: '#D7264A',
      ':focus': {
        color: '#e44d5f',
      },
      '::placeholder': {
        color: '#FFCCA5',
      },
    },
  }

  classes = {
    focus: 'focus',
    complete: 'complete',
    invalid: 'invalid',
    empty: 'empty',
  }

  errors = {}
  errorMessage = ''
  fullName = ''

  onChange(status: any): void {
    const errors = {
      ...this.errors,
      [status.field]: status.error,
    }
    this.errors = errors
    const { message } = Object.values(errors).filter(message => !!message).pop() || {} as any
    this.errorMessage = message
  }

  onReady(e: HTMLInputElement): void {
    e.focus()
  }

  async onSubmit(): Promise<void> {
    try {
      if (this.fullName.trim() === '') {
        this.errorMessage = 'Please provide a name on card'
        return
      }
      const data = await this.createPaymentIntent({ method: 'card' })
      const authorizedIntent = await (this.$refs.cardComponent as any).authorizeWith3ds(data.payment_intent, {
        billingAddress: {
          lastName: this.fullName,
        },
      })
      const signupToken = await this.signup({ method: 'card', data: authorizedIntent.id })
      this.$router.push('/set-password?token=' + signupToken)
    } catch (e) {
      // All kinds of errors are thrown here, log the error and show general message.
      console.warn('Error processing CC payment:', e)
      throw new Error(this.$t('signUp.ccError').toString())
    }
  }
}
