



















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TeamsTable extends Vue {
  @Prop()
  value: number

  @Prop()
  lastPage: boolean

  previousPage() {
    if (this.value < 2) {
      return
    }
    this.$emit('input', this.value - 1)
  }

  nextPage() {
    if (this.lastPage) {
      return
    }
    this.$emit('input', this.value + 1)
  }
}

