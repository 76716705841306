









import { Getter } from 'vuex-class'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Token extends Vue {
  @Getter token
}
