import store from '@/store'

export const authGuard = async (to, from, next): Promise<void> => {
  const token = store.getters.token
  if (token == null) {
    if (window.location.href.includes('#') && to.path === '/microsoft-login/') {
      const queryParameters = window.location.href.split('#')[1].split('&')
      const token = queryParameters.find(q => q.startsWith('id_token')).split('=')[1]
      try {
        await store.dispatch('exchangeMicrosoftToken', token)
      } catch (e) {
        next('/login')
        return
      }
      next()
      return
    }
    if (!['/login', '/oauth'].includes(to.path)) {
      next('/login')
      return
    }
    next()
    return
  }

  const permission = to.matched[0].meta?.permission
  if (permission != null) {
    if (!store.getters.permissions.includes(permission)) {
      next('/home')
      return
    }
  }

  const user = store.getters.user
  if (user == null) {
    store.dispatch('fetchUser')
  }

  next()
}
