

















import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'

@Component
export default class NormalLayout extends Vue {
  @Mutation setAlert
  @Getter subscription
  @Getter activeSubscription

  @Watch('subscription')
  displaySubscriptionWarning(): void {
    if (!this.activeSubscription) {
      const route = this.subscription.accountType === 'activation-code'
        ? '/profile/activate-subscription'
        : '/profile/select-subscription'

      this.setAlert({
        text: this.$t('general.subscriptionExpired'),
        link: route,
      })
    }
  }
}
