























import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

@Component
export default class SelectSubscriptionPage extends Vue {
  @Action extendSubscriptionActivationCode

  error?: string = null

  newCode = ''
  // 01CA-31A2-B1CF-D66A-4BE2
  regex = /^(?:[a-zA-Z0-9]{4}[-]){4}([a-zA-Z0-9]{4})$/

  get enabled(): boolean {
    return !!this.newCode && this.regex.test(this.newCode)
  }

  async submit(): Promise<void> {
    try {
      await this.extendSubscriptionActivationCode({ code: this.newCode })
      this.newCode = ''
      this.$router.push('/profile', () => {
        this.$router.go(0)
      })
    } catch (error: any) {
      const errorMessages = {
        E2202: this.$t('errorMessages.reusedCode'),
      }

      if (error.response.data.code && error.response.data.code in errorMessages) {
        this.error = errorMessages[error.response.data.code]
      } else {
        this.error = error.response.data.message ?? this.$t('errorMessages.unknownError')
      }
    }
  }
}
