/* eslint-disable prefer-rest-params */
// Notice that Google Analytics 4 is loaded via Google Tag manager, see integration details here: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce

import { EstimateCreateResult, GooseItemPrice, SubscriptionCreateResult } from './types/signUpModels'

declare global {
  interface Window {
    dataLayer: any[]
  }
}

window.dataLayer = window.dataLayer || []

export function gaSetUserId(userId?: string) {
  // It could be that GA is not loaded yet, so we push this event later on
  setTimeout(() => window.dataLayer.push({
    user_id: userId,
  }), 5000)
}

export function gaLogin(method: string) {
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#login
  window.dataLayer.push({
    event: 'login',
    method,
  })
}

// Called after loading deals
export function gaViewItemList(items: GooseItemPrice[]) {
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_item_list
  ecommerce('view_item_list', {
    items: items.map((i, index) => (
      {
        item_id: i.id,
        item_name: i.name,
        currency: i.currencyCode,
        index,
        item_brand: 'GOOSE VPN',
        price: i.price / 100,
        quantity: 1,
      }
    )),
    // [
    //   {
    //     item_id: 'SKU_12345',
    //     item_name: 'Stan and Friends Tee',
    //     affiliation: 'Google Merchandise Store',
    //     coupon: 'SUMMER_FUN',
    //     currency: 'USD',
    //     discount: 2.22,
    //     index: 0,
    //     item_brand: 'Google',
    //     item_category: 'Apparel',
    //     item_category2: 'Adult',
    //     item_category3: 'Shirts',
    //     item_category4: 'Crew',
    //     item_category5: 'Short sleeve',
    //     item_list_id: 'related_products',
    //     item_list_name: 'Related Products',
    //     item_variant: 'green',
    //     location_id: 'L_12345',
    //     price: 9.99,
    //     quantity: 1,
    //   },
    // ],
  })
}

// Called after "pay" click
export function gaBeginCheckout(estimate: EstimateCreateResult, paymentMethod: string) {
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#begin_checkout
  ecommerce('begin_checkout', {
    currency: estimate.invoiceEstimate.currencyCode,
    value: estimate.invoiceEstimate.total / 100,
    coupon: estimate.appliedCouponCode,
    items: estimate.invoiceEstimate.lineItems.map((i, index) => (
      {
        item_id: i.entityId,
        item_name: i.description,
        currency: estimate.invoiceEstimate.currencyCode,
        index,
        item_brand: 'GOOSE VPN',
        price: i.amount / 100,
        quantity: i.quantity,
      }
    )),
  })
}

// Called affter "pay" click
export function gaAddPaymentInfo(estimate: EstimateCreateResult, paymentMethod: string) {
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#add_payment_info
  ecommerce('add_payment_info', {
    currency: estimate.invoiceEstimate.currencyCode,
    value: estimate.invoiceEstimate.total / 100,
    coupon: estimate.appliedCouponCode,
    payment_type: paymentMethod,
    items: estimate.invoiceEstimate.lineItems.map((i, index) => (
      {
        item_id: i.entityId,
        item_name: i.description,
        currency: estimate.invoiceEstimate.currencyCode,
        index,
        item_brand: 'GOOSE VPN',
        price: i.amount / 100,
        quantity: i.quantity,
      }
    )),
  })
}

// Called after "signup"
export function gaPurchase(subscription: SubscriptionCreateResult) {
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#purchase
  ecommerce('purchase', {
    transaction_id: subscription.invoice.id,
    value: subscription.invoice.total / 100,
    tax: subscription.invoice.tax / 100,
    currency: subscription.invoice.currencyCode,
    coupon: subscription.appliedCouponCode,
    items: subscription.invoice.lineItems.map((i, index) => (
      {
        item_id: i.entityId,
        item_name: i.description,
        currency: subscription.invoice.currencyCode,
        index,
        item_brand: 'GOOSE VPN',
        price: i.amount / 100,
        quantity: i.quantity,
      }
    )),
  })
}

function ecommerce(event: string, ecommerce: any) {
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event,
    ecommerce,
  })
}
