



























import { Action, Getter } from 'vuex-class'
import { Component, Vue } from 'vue-property-decorator'
import { UserResponse } from '@/types/userModels'
import { SectionType } from '@/store/chargebeePortal'

@Component
export default class RowSelectPay extends Vue {
  @Getter user: UserResponse;
  @Getter hasValidPaymentMethod;
  @Action selectSubscription;
  @Action fetchUserPaymentMethods;
  @Action openPortalSection;
  @Action fetchUser;
  loading = false;

  async submit() {
    this.loading = true
    try {
      await this.selectSubscription()
      await this.fetchUser()
      this.$router.push('/profile')
    } finally {
      this.loading = false
    }
  }

  async openPaymentMethods() {
    await this.openPortalSection({
      sectionType: SectionType.PortalPaymentMethods,
      callbacks: {
        close: async () => {
          await this.fetchUserPaymentMethods()
        },
      },
    })
  }
}
