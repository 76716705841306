





















import { Component, Prop, Vue } from 'vue-property-decorator'
import MenuKebab from '@/assets/menu-kebab.svg'

@Component({
  components: { MenuKebab },
})
export default class TableActionsIcon extends Vue {
  @Prop()
  id: string

  @Prop({ required: false, default: true })
  edit: boolean

  @Prop({ required: false, default: true })
  deactivate: boolean

  @Prop({ required: false, default: true })
  remove: boolean

  onEdit(id) {
    this.$emit('edit', id)
  }

  onDeactivate(id) {
    this.$emit('deactivate', id)
  }

  onRemove(id) {
    this.$emit('delete', id)
  }

  command({ callback, id }) {
    callback(id)
  }
}

