import { Vue } from 'vue-property-decorator'
import Vuex from 'vuex'

import users from './users'
import notification from './notification'
import vpnServer from './vpnServer'
import signUp from './signUp'
import alert from './alert'
import chargebeePortal from './chargebeePortal'

Vue.use(Vuex)

const modules = {
  users,
  notification,
  vpnServer,
  signUp,
  alert,
  chargebeePortal,
}

export default new Vuex.Store({
  modules,
})
