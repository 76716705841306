






import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

@Component
export default class PaymentPayPal extends Vue {
  @Action createPaymentIntent;
  @Action saveState;

  async onSubmit(): Promise<void> {
    const data = await this.createPaymentIntent({ method: 'paypal' })
    await this.saveState()
    window.location.href = data.redirect_uri
  }
}
