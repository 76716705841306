











































import { Component, Vue } from 'vue-property-decorator'
import { PlanResponse } from '@/types/userModels'
import { Action, Getter, Mutation } from 'vuex-class'
import { addDays, format } from 'date-fns'
import { getDateFNSLang } from '@/translations'
import { LifetimePlanIds } from '@/enums/enums'

@Component
export default class MySubscriptionCard extends Vue {
  @Getter subscription: PlanResponse
  @Action openSubscription
  @Mutation closeAlert

  openSub(): any {
    return this.openSubscription({ subscriptionIds: this.subscription?.referenceIds })
  }

  isActive(): boolean {
    return this.subscription.status === 'Active'
  }

  get deviceLimitText(): string {
    if (this.subscription.deviceLimit == null) {
      return this.$t('profile.subs.deviceLimit.unlimited') as string
    }
    return this.$t('profile.subs.deviceLimit.limited', { limit: this.subscription.deviceLimit }) as string
  }

  get canSelectSubscription(): boolean {
    return (this.subscription.status !== 'Active' && ['ios', 'android', 'placeholder', 'chargebee'].includes(this.subscription.accountType))
  }

  get hasActiveChargebeeSubscription(): boolean {
    return this.subscription.status === 'Active' && this.subscription.accountType === 'chargebee'
  }

  get isLifetimePlan(): boolean {
    return this.planIds.some(planId => planId in LifetimePlanIds)
  }

  get planIds(): string[] {
    return this.subscription.name.split(', ')
  }

  get text(): string {
    if (this.subscription.isLifetime) {
      const text = [this.$t('profile.subs.lifetime').toString()]

      if (this.subscription.accountType === 'chargebee') {
        text.push(this.$t('profile.subs.lifetimeCAchargeBee').toString())
      }
      if (this.subscription.accountType === 'app-sumo') {
        text.push(this.$t('profile.subs.lifetimeCAappSumo').toString())
      }
      return text.join('<br>')
    }

    const options = {
      date: format(addDays(new Date(this.subscription.nextDueDate), 1), 'dd MMMM yyyy', { locale: getDateFNSLang() }),
      name: this.subscription.name,
      status: this.isActive() ? this.$t('profile.subs.status.active').toString() : this.$t('profile.subs.status.inactive').toString(),
    }

    return this.$t(`dynamic.profile.subscriptionInformation.${this.subscription.accountType}`, options).toString()

    // Returns one of the following translations based on the subscription account type:
    // $t('dynamic.profile.subscriptionInformation.activation-code')
    // $t('dynamic.profile.subscriptionInformation.b2b')
    // $t('dynamic.profile.subscriptionInformation.android')
    // $t('dynamic.profile.subscriptionInformation.ios')
    // $t('dynamic.profile.subscriptionInformation.chargebee')
    // $t('dynamic.profile.subscriptionInformation.placeholder')
  }
}
