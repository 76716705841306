import { render, staticRenderFns } from "./PaymentRedirect.vue?vue&type=template&id=9888ce5a&scoped=true&"
import script from "./PaymentRedirect.vue?vue&type=script&lang=ts&"
export * from "./PaymentRedirect.vue?vue&type=script&lang=ts&"
import style0 from "./PaymentRedirect.vue?vue&type=style&index=0&id=9888ce5a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9888ce5a",
  null
  
)

export default component.exports