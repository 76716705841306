import { Alert } from '@/types/alertModels'

export default {
  state: () => {
    return {
      alert: null,
    }
  },

  getters: {
    alert(state): string[] {
      return state.alert
    },
  },

  mutations: {
    closeAlert(state, value): void {
      console.log('close')
      state.alert = null
    },
    setAlert(state, alert: Alert): void {
      state.alert = alert
    },
  },

  actions: {
  },
}
