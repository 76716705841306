




















import { Component, Vue, Watch } from 'vue-property-decorator'

interface FooterData {
  title: string
  lines: { text: string, link?: string }[]
}

@Component
export default class SpecialFooter extends Vue {
  content: FooterData[] = null
  dataPath = 'data'
  currentYear = (new Date()).getFullYear()

  created(): void {
    this.content = this.getData()
  }

  getData(): FooterData[] {
    return this.$t('ignore.footer.data') as unknown as FooterData[]
  }

  @Watch('$i18n.locale')
  localChange(): void {
    this.content = this.getData()
  }
}
