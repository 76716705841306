import VueI18n from 'vue-i18n'
import Vue from 'vue'

import en from './lang/en.json'
import nl from './lang/nl.json'
import de from './lang/de.json'
import fr from './lang/fr.json'
import { enUS as dateFNSLocaleEN, nl as dateFNSLocaleNL } from 'date-fns/locale'

export const translations = { en, nl, de, fr }

export interface Language {
  localeISO: string
  locale: string
  flag: string
  language: string
}

export const languages: Language[] = [
  {
    locale: 'en',
    localeISO: 'en-US',
    flag: 'GB',
    language: 'English',
  },
  {
    locale: 'nl',
    localeISO: 'nl-NL',
    flag: 'NL',
    language: 'Dutch',
  },
  {
    locale: 'de',
    localeISO: 'de-DE',
    flag: 'DE',
    language: 'German',
  },
  {
    locale: 'fr',
    localeISO: 'fr-FR',
    flag: 'FR',
    language: 'French',
  },
]

Vue.use(VueI18n)

function getLang() {
  if (localStorage.getItem('language')) {
    return localStorage.getItem('language')
  }

  if (navigator.languages && languages.find(l => l.localeISO === navigator.languages[0])) {
    return languages.find(l => l.localeISO === navigator.languages[0]).locale
  }

  if (navigator.language && languages.find(l => l.localeISO === navigator.language)) {
    return languages.find(l => l.localeISO === navigator.language).locale
  }
  return 'en'
}

export const i18n = new VueI18n({
  locale: getLang(),
  messages: translations,
})

export function getDateFNSLang(): Locale {
  if (i18n.locale === 'nl') {
    return dateFNSLocaleNL
  }
  return dateFNSLocaleEN
}
