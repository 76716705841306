













import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import { Alert } from '@/types/alertModels'

@Component
export default class AlertBar extends Vue {
  @Getter alert?: Alert;
  @Mutation closeAlert;
}
