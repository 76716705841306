

































































































































import { GooseItemPrice, GooseItemPriceTranslatedMetadata } from '@/types/signUpModels'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

@Component
export default class RowCyberAlarm extends Vue {
  @Getter hasCyberAlarm: boolean;
  @Getter addon: GooseItemPrice;
  @Getter currencyCode: string;
  @Action toggleCyberAlarm;

  get buttonText(): string {
    return this.hasCyberAlarm
      ? this.$t('signUp.delete').toString()
      : this.$t('signUp.add').toString()
  }

  get meta(): GooseItemPriceTranslatedMetadata {
    return this.addon.metadata?.translations?.[this.$i18n.locale] ?? {
      productFeatures: ['Not configured'],
      productUsp: '-',
      subtitle: undefined,
      title: undefined,
    }
  }

  get addonPrice(): number {
    return this.addon.periodUnit === 'month'
      ? this.addon.price
      : this.addon.price / 12
  }
}
