




















import { Action, Getter } from 'vuex-class'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class RowSignUpEmail extends Vue {
  @Getter email: string
  @Getter emailError: boolean
  @Action setEmailAddress

  destroyed(): void {
    this.setEmailAddress(null)
  }
}
