
































import { getPasswordCheck, isDisabled } from '@/utils'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'

// 3 Ways to end up here:
//   1. Reset password => Email (click) => User already had a password and wants to change it
//   2. Reset password => Email (click) => User didn't have a password and wants to set it
//   3. Initial signup => After payment => User wants to configure a password
// We know in which flow we are by the extra param has_password, set in the email (case 1,2)
// The flow stays the same, we call set-password with the set-password token

@Component
export default class SetPassword extends Vue {
  @Getter microsoftLoginUrl: string;
  @Mutation token;
  @Action setPassword;

  password1 = ''
  password2 = ''

  param = this.$route.query.has_password

  async submit(): Promise<void> {
    await this.setPassword({ token: this.$route.query.token, password: this.password1 })
    this.$router.push('/')
  }

  get disabled(): boolean {
    return isDisabled(this.password1, this.password2)
  }

  get passwordInfo(): string {
    const check = getPasswordCheck(this.password1, this.password2)
    if (check === null) return ' '

    if (check) return this.$t('profile.passwordVerify').toString()
    return this.$t('profile.passwordInfo').toString()
  }

  title(): string {
    if (this.param === undefined) {
      return this.$t('setPassword.initialPassword.title') as string
    } else if (this.param === 'true') {
      return this.$t('setPassword.resetPassword.title') as string
    } else {
      return this.$t('setPassword.setPassword.title') as string
    }
  }

  subtitle(): string {
    if (this.param === undefined) {
      return this.$t('setPassword.initialPassword.subtitle') as string
    } else if (this.param === 'true') {
      return this.$t('setPassword.resetPassword.subtitle') as string
    } else {
      return this.$t('setPassword.setPassword.subtitle') as string
    }
  }
}
