











import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

@Component
export default class DesktopHeader extends Vue {
  @Action logout;

  async handleLogout(): Promise<void> {
    this.$router.push('/login')
    await this.logout()
  }
}
