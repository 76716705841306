




























import { Component, Vue } from 'vue-property-decorator'
import { SpecialNavBar } from '../routes'

@Component
export default class SpecialNavBarLayout extends Vue {
  get specialNavBar(): SpecialNavBar {
    return this.$route.meta.specialNavBar
  }

  navigate(to: string): void {
    this.$router.push(to)
  }
}
