import { request } from '@/api'
import { Server } from '@/types/vpnServerModels'
import { getCamelCase } from '@/utils'

export default {
  state: () => {
    return {
      serverList: [],
    }
  },
  getters: {
    serverList(state): Server[] {
      return state.serverList
    },
  },
  mutations: {
    serverList(state, value): void {
      state.serverList = value
    },
  },
  actions: {
    async fetchServerList({ commit }): Promise<void> {
      const { data } = await request('GET', '/users/me/servers')
      commit('serverList', getCamelCase<Server[]>(data))
    },
  },
}
