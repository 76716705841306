import { authGuard } from './auth-guard'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import SetPassword from './views/SetPassword.vue'
import DownloadsPage from './views/DownloadsPage.vue'
import ProfilePage from './views/ProfilePage.vue'
import SelectSubscriptionPage from './views/SelectSubscriptionPage.vue'
import ActivateSubscriptionPage from './views/ActivateSubscriptionPage.vue'
import UpgradeSubscriptionPage from './views/UpgradeSubscriptionPage.vue'
import CyberAlarm from './views/CyberAlarm.vue'
import SignUp from './views/SignUp.vue'
import SignUpComplete from './views/SignUpComplete.vue'
import SignUpViaActivationCode from './views/SignUpViaActivationCode.vue'
import PaymentRedirect from './views/PaymentRedirect.vue'

Vue.use(VueRouter)

// There is a special navbar, usually used when the user is not logged in
// It also results in the page being centered.
export interface SpecialNavBar {
  text: string
  linkText: string
  linkPath: string
}

const auth = { beforeEnter: authGuard }

const routes = [
  { ...auth, path: '/', component: Home },
  { ...auth, path: '/microsoft-login/', component: Home },
  { ...auth, path: '/cyber-alarm', component: CyberAlarm },
  { ...auth, path: '/downloads', component: DownloadsPage },
  { ...auth, path: '/profile', component: ProfilePage },
  { ...auth, path: '/profile/select-subscription', component: SelectSubscriptionPage },
  { ...auth, path: '/profile/activate-subscription', component: ActivateSubscriptionPage },
  { ...auth, path: '/profile/upgrade-subscription', component: UpgradeSubscriptionPage },
  {
    path: '/sign-up',
    component: SignUp,
    meta: {
      specialNavBar: {
        text: 'dynamic.signUp.alreadyAccount',
        linkText: 'dynamic.signUp.login',
        linkPath: '/login',
      } as SpecialNavBar,
    },
  },
  {
    path: '/sign-up-complete',
    component: SignUpComplete,
    meta: {
      specialNavBar: {
        text: 'dynamic.signUp.alreadyAccount',
        linkText: 'dynamic.signUp.login',
        linkPath: '/login',
      } as SpecialNavBar,
    },
  },
  {
    path: '/activate',
    component: SignUpViaActivationCode,
    meta: {
      specialNavBar: {
        text: 'dynamic.signUp.alreadyAccount',
        linkText: 'dynamic.signUp.login',
        linkPath: '/login',
      } as SpecialNavBar,
    },
  },
  {
    path: '/payment-redirect',
    component: PaymentRedirect,
    meta: {
      specialNavBar: {
        text: 'dynamic.signUp.alreadyAccount',
        linkText: 'dynamic.signUp.login',
        linkPath: '/login',
      } as SpecialNavBar,
    },
  },
  {
    path: '/set-password',
    component: SetPassword,
    meta: {
      specialNavBar: {
        text: 'dynamic.signUp.alreadyAccount',
        linkText: 'dynamic.signUp.login',
        linkPath: '/login',
      } as SpecialNavBar,
    },
  },
  {
    ...auth,
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      specialNavBar: {
        text: 'dynamic.login.noAccount',
        linkText: 'dynamic.login.register',
        linkPath: '/sign-up',
      } as SpecialNavBar,
    },
  },
  { ...auth, path: '*', redirect: '/' },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.onError(err => {
  console.error('router.onError:', err)
})

export default router
