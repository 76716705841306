<template>
  <div class="input">
    <el-input
      v-model="number"
      :disabled="disabled"
      :min="min"
      :max="max"
      :placeholder="placeholder"
      @blur="blurInput"
      @change="changeInput"
      @focus="$event.target.select()">
      <slot :slot="slotPosition"></slot>
    </el-input>
  </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({

  props: {
    value: { type: Number, default: null },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: '0' },
    float: { type: Boolean, default: false },
    precision: { type: Number, default: 2 },
    slotPosition: { type: String, default: 'prepend' },
  },

  data() {
    return {
      currentNumber: null,
    }
  },

  computed: {
    number: {
      get() {
        if ((this.currentNumber != null && this.currentNumber.slice(-1) === '.') ||
          (this.currentNumber != null && this.currentNumber === '-')
        ) {
          return this.currentNumber
        } else {
          return this.value
        }
      },
      set(number) {
        if (number === '') {
          this.currentNumber = null
          this.$emit('input', null)
          return
        }
        this.currentNumber = number
        if (number.slice(-1) === '.') return
        if (number.length === 1 && number === '-') { return }
        let parsedNumber
        if (this.float) {
          parsedNumber = parseFloat(number)
          if (this.precision) {
            parsedNumber = Math.floor(parsedNumber * 10 ** this.precision) / 10 ** this.precision
          }
        } else {
          parsedNumber = parseInt(number)
        }
        if (isNaN(parsedNumber)) return
        this.$emit('input', parsedNumber)
      },
    },
  },

  methods: {
    blurInput(e) {
      this.$emit('blur', e)
    },

    changeInput(e) {
      this.$emit('change', this.value)
    },
  },

})
</script>

<style lang="scss">

.input {
  width: 100%;
  .el-input .el-input__inner {
    padding: 4px 15px;
  }
}

</style>
