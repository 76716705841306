








import { Component, Prop, Vue } from 'vue-property-decorator'

const components: Record<string, any> = {}
const requireComponent = require.context('../', true, /(\.svg)$/)
requireComponent.keys().forEach(fileName => {
  const match = fileName.match(/(\w+)\.\w+$/)
  if (match) {
    const req = requireComponent(fileName)
    components[match[1]] = req
  }
})

@Component({ components })
export default class IconCollection extends Vue {
  svgNames = Object.keys(components)
}
