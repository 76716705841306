import store from './store'

function permitted(requiredPermissions: string|string[]) {
  if (requiredPermissions == null) {
    return true
  }
  const permissions = store.getters.permissions
  if (permissions !== null) {
    if (permissions.length > 0) {
      // Check if (at least one of) the given permission(s) is one of the user's permissions
      if (Array.isArray(requiredPermissions)) {
        return requiredPermissions.some(el => permissions.indexOf(el) !== -1)
      } else {
        return permissions.indexOf(requiredPermissions) !== -1
      }
    } else {
      return false
    }
    return null
  }
}

export async function vPermitted(el, binding, vnode) {
  if (permitted(binding.value) === false) {
    el.innerHTML = null
    el.style.display = 'none'
  }
}

export async function vEditable(el, binding, vnode) {
  if (permitted(binding.value) === false) {
    const inputElements = el.querySelectorAll('input')
    for (const element of inputElements) {
      element.disabled = true
      element.parentNode.classList.add('is-disabled')
    }
    const buttons = el.querySelectorAll('button')
    for (const button of buttons) {
      button.disabled = true
      button.classList.add('is-disabled')
    }
  }
}
