























import { Component, Vue } from 'vue-property-decorator'
import { Getter, Action, Mutation } from 'vuex-class'

@Component
export default class MyAccountCard extends Vue {
  @Getter user
  @Getter updateEmailError
  @Mutation setUpdateEmailError
  @Action updateEmail

  newEmail = ''

  get disabled(): boolean {
    return this.emailNotValid(this.newEmail) || this.user.email === this.newEmail
  }

  emailNotValid(email: string): boolean {
    // eslint-disable-next-line no-useless-escape
    const regex = /^\w+([\.-/+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const matches = regex.exec(email)
    return matches === null
  }

  async submit(): Promise<void> {
    await this.updateEmail(this.newEmail)
  }
}
