




















































import { Component, Prop, Vue } from 'vue-property-decorator'

export interface DownloadBarMenuData {
  title: string
  link: string
}

export interface DownloadBarData {
  icon: string
  link?: string
  name: string
  image?: string
  imageLink?: string
  description?: string
  redirect?: boolean
  text?: string
  showDownloadIcon?: boolean
  menu?: DownloadBarMenuData[]
}

@Component
export default class DownloadBarContainer extends Vue {
  @Prop(Object) data: DownloadBarData

  download(url: string): void {
    window.open(url, '_blank').focus()
  }
}
