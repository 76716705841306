






































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { PlanResponse } from '@/types/userModels'
import { LifetimePlanIds } from '@/enums/enums'

@Component
export default class SelectSubscriptionPage extends Vue {
  @Getter subscription: PlanResponse
  @Getter subscriptionIds: string[]
  @Action upgradeLifetimeChargebeeSubscription

  lifetimePlans = LifetimePlanIds

  upgradePlanId: string = null
  error?: string = null
  loading = false
  cyberAlarm = false

  @Watch('subscription', { immediate: true })
  onSubscriptionChanged() {
    this.cyberAlarm = this.subscription?.hasAlarms > 0 || false
  }

  get submitDisabled(): boolean {
    return this.upgradePlanId == null &&
      this.cyberAlarm === (this.subscription?.hasAlarms > 0)
  }

  get currentLifetimePlanId(): string {
    return this.subscriptionIds?.find(planId => planId in LifetimePlanIds)
  }

  isSelectable(planId: string): boolean {
    return parseInt(LifetimePlanIds[planId]) > parseInt(LifetimePlanIds[this.currentLifetimePlanId])
  }

  async submit(): Promise<void> {
    this.loading = true
    try {
      const itemPrices = [this.upgradePlanId]
      if (this.cyberAlarm) {
        itemPrices.push('Cyber-Alarm-EUR-Yearly')
      }

      await this.upgradeLifetimeChargebeeSubscription({ itemPrices })

      this.$router.push('/profile', () => {
        this.$router.go(0)
      })
    } catch (error: any) {
      this.error = error.response.data.message ?? this.$t('errorMessages.unknownError')
    }
    this.loading = false
  }
}
