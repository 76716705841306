
















































































import { GooseItemPrice, GooseItemPriceTranslatedMetadata } from '@/types/signUpModels'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

@Component
export default class DealCard extends Vue {
  @Prop({ required: true })
  item: GooseItemPrice;

  @Getter deal: GooseItemPrice;
  @Getter deals: GooseItemPrice[];
  @Getter currencyCode: string;
  @Action switchCurrencyCode;
  @Action selectDeal;

  get meta(): GooseItemPriceTranslatedMetadata {
    return this.item.metadata?.translations?.[this.$i18n.locale] ?? {
      productFeatures: ['Not configured'],
      productUsp: '-',
      subtitle: undefined,
      title: undefined,
    }
  }

  get isActive(): boolean {
    return this.item.id === this.deal.id
  }

  get price(): number {
    return this.getPricePerMonth(this.item)
  }

  get period(): string {
    if (this.item.periodUnit === 'month') {
      return this.$t('signUp.monthly').toString()
    }
    if (this.item.periodUnit === 'year') {
      return `${this.item.period} ${this.$t('signUp.year')}`
    }
  }

  getPricesPerMonth(): number[] {
    return this.deals.map((deal) => this.getPricePerMonth(deal))
  }

  getPricePerMonth(item: GooseItemPrice): number {
    if (item.periodUnit === 'year') {
      return item.price / item.period / 12
    } else if (item.periodUnit === 'month') {
      return item.price
    }
  }
}
