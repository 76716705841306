import { request } from '@/api'
declare const Chargebee: any

export enum SectionType {
  SubDetails = 'sub_details',
  PortalPaymentMethods = 'portal_payment_methods',
  PortalBillingHistory = 'portal_billing_history',
  PortalAddress = 'portal_address',
}

export default {
  state: () => {
    return {
    }
  },

  getters: {},

  mutations: {},

  actions: {
    async openSubscription({ commit, state, dispatch }, { subscriptionIds }): Promise<void> {
      if (subscriptionIds.length === 1) {
        return await dispatch('openPortalSection', {
          sectionType: SectionType.SubDetails,
          options: {
            params: {
              subscriptionId: subscriptionIds[0],
            },
          },
        })
      } else {
        return await dispatch('openPortal')
      }
    },
    async openPortal({ commit, state }, params): Promise<void> {
      const chargebeeInstance = Chargebee.getInstance()

      chargebeeInstance.setPortalSession(async function () {
        const { data } = await request('POST', '/portal-session', {})
        return data
      })

      const cbPortal = chargebeeInstance.createChargebeePortal()

      cbPortal.open(params?.options, params?.forwardOptions)
    },
    async openPortalSection({ commit, state }, { sectionType, options, callbacks }): Promise<void> {
      const chargebeeInstance = Chargebee.getInstance()

      chargebeeInstance.setPortalSession(async function () {
        const { data } = await request('POST', '/portal-session', {})
        return data
      })

      const cbPortal = chargebeeInstance.createChargebeePortal()

      cbPortal.openSection({
        sectionType,
        ...options,
      }, callbacks)
    },
  },
}
