import * as dateFns from 'date-fns'

import { transform, isEqual, isObject, isPlainObject, isArray, reduce, merge, camelCase } from 'lodash'
import { UserResponse } from './types/userModels'

/**
 * Returns an object containing the fields in which an object differs from a base object.
 * Not prepared for circular dependency.
 * @param {object} object - The object to compare.
 * @param {object} base - The base object to compare to.
 * */
export function deepObjectsDifference(object = {}, base = {}) {
  return transform(object, (result, value, key) => {
    const comperator = base == null ? undefined : base[key]
    if (!isEqual(value, comperator)) {
      result[key] = isObject(value) && isObject(comperator) ? deepObjectsDifference(value, comperator) : value
    }
  })
}

/**
 * Recursively walks down an object by always taking the first available key
 * until it finds a primitive type or an array. Then returns the path.
 * @param {object} object - The object to inspect.
 * @returns {object} A one level deep object with paths to nested values as keys.
 */
export const convertNestedObjectToPath = (obj, path = []) =>
  (isPlainObject(obj) || isArray(obj))
    ? reduce(obj, (cum, next, key) => merge(cum, convertNestedObjectToPath(next, [...path, key])), {})
    : { [path.join('.')]: obj }

export function formatDate(date, options) {
  return dateFns.format(new Date(date), options)
}

/**
 * Formats a string so that only the first letter is upper case, the rest is lower case
 * @param string - The string to format
 */
export function capitalName(string) {
  return upperCaseFirstLetter(string.toLowerCase())
}

function upperCaseFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function parseUserResponse(data: any): UserResponse {
  return getCamelCase<UserResponse>({
    ...data,
    is_b2b: !!data.is_b2b,
    has_alarms: !!data.has_alarms,
    cyber_alarm_letter: !!data.cyber_alarm_letter,
    marketing_letter: !!data.marketing_letter,
    news_letter: !!data.news_letter,
  })
}

export function getCamelCase<T>(o: Record<string, unknown> | Array<unknown>): T {
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === 'object') {
        value = getCamelCase(value as any)
      }
      return value
    }) as unknown as T
  } else {
    const newO = {}

    for (const origKey in o) {
      const newKey = camelCase(origKey)
      let value = o[origKey]
      if (value instanceof Array || (value !== null && value.constructor === Object)) {
        value = getCamelCase(value as any)
      }
      newO[newKey] = value
    }
    return newO as T
  }
}

export function isDisabled(newPassword: string, verifyPassword: string): boolean {
  const lengthCheck = newPassword.length >= 8 && verifyPassword.length >= 8
  const verifyCheck = newPassword === verifyPassword

  const passed = lengthCheck && verifyCheck

  return !passed
}

export function getPasswordCheck(newPassword: string, verifyPassword: string): boolean | null {
  const filledCheck = newPassword !== '' && verifyPassword !== ''
  const lengthCheck = newPassword.length >= 8 && verifyPassword.length >= 8
  const verifyCheck = newPassword !== verifyPassword

  if (filledCheck && !lengthCheck) {
    return false
  } else if (filledCheck && lengthCheck && verifyCheck) {
    return true
  }
  return null
}
