




























import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export default class DesktopNavBar extends Vue {
  @Getter user

  get hasAlarms() {
    return this.user?.hasAlarms
  }

  get route(): string {
    return this.$route.path
  }

  open(url: string): void {
    // console.log(url)
    window.open(url, '_blank').focus()
  }
}

