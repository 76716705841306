






































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Component
export default class MobileHeader extends Vue {
  @Action logout;
  @Getter user;

  showMenu = false;

  get hasAlarms() {
    return this.user?.hasAlarms
  }

  get route(): string {
    return this.$route.path
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu
  }

  open(url: string): void {
    window.open(url, '_blank').focus()
  }

  async handleLogout(): Promise<void> {
    this.$router.push('/login')
    await this.logout()
  }
}
