





















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class SpecialFooter extends Vue {
  rating = 4.1
  reviews = 169

  get ratingRounded(): number {
    return Math.round(this.rating)
  }
}
