














import { Component, Vue } from 'vue-property-decorator'
import GuusFlying from '../assets/GuusFlying.svg'

@Component({
  components: {
    GuusFlying,
  },
})
export default class HomePage extends Vue { }
