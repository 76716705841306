





import { Watch, Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { languages } from './translations'
declare const Chargebee: any

@Component
export default class App extends Vue {
  @Getter messages;

  get hasSpecialNavBar(): boolean {
    return this.$route.meta.specialNavBar ?? false
  }

  mounted(): void {
    Chargebee.init({
      site: process.env.VUE_APP_CHARGEBEE_SITE,
      publishableKey: process.env.VUE_APP_CHARGEBEE_KEY,
    })

    if (this.$route.query.locale && languages.find(l => l.locale === this.$route.query.locale)) {
      localStorage.setItem('language', this.$route.query.locale as string)
      this.$i18n.locale = this.$route.query.locale as string
    }
  }

  @Watch('messages')
  displayMessage(): void {
    if (this.messages.length < 1) {
      return
    }
    const message = this.messages.shift()
    if (message.extensions.status != null) {
      if (message.extensions.status === 200) {
        this.$message.success(message.message)
        return
      }
      if (message.extensions.status === 403) {
        if (this.$route.path !== '/') {
          this.$router.push('/')
        }
      }
      this.$message({
        message: `(${message.extensions.status}) - ${message.message}`,
        type: 'error',
      })
      return
    }
    this.$message(message.message)
  }
}
