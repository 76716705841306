



















import { Getter } from 'vuex-class'
import { Component, Vue } from 'vue-property-decorator'
import { GooseItemPrice } from '@/types/signUpModels'

@Component
export default class RowDeal extends Vue {
  @Getter deals: GooseItemPrice[]

  get recurringDeals(): GooseItemPrice[] {
    return this.deals.filter((x) => x.price !== 0)
  }

  // filter all deals that are lifetime deals
  get lifetimeDeals(): GooseItemPrice[] {
    return this.deals.filter((x) => x.price === 0)
  }

  getOrder(item: GooseItemPrice): number {
    return item.metadata?.desktopOrder || 1
  }
}
