









































import { gaAddPaymentInfo, gaBeginCheckout } from '@/ga'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export default class PaymentButton extends Vue {
  @Getter email;
  @Getter estimate;

  @Prop({ required: true })
  title: string;

  @Prop({ required: true })
  method: string;

  click(): void {
    gaBeginCheckout(this.estimate, this.method)
    gaAddPaymentInfo(this.estimate, this.method)
    this.$emit('click')
  }

  get disabledReason(): string {
    if (this.email === null || this.email.length === 0) {
      return this.$t('signUp.missingEmail').toString()
    }

    const invalid = !this.email.toLowerCase()
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

    if (invalid) {
      return this.$t('signUp.invalidEmail').toString()
    }
  }
}
