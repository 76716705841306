





























import { PlanResponse } from '@/types/userModels'
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

@Component
export default class ProfilePage extends Vue {
  nav = 'general';
  @Getter subscription: PlanResponse;
}
