

















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class DownloadsPage extends Vue {
  nav = 'apps'
}
