













import { SectionType } from '@/store/chargebeePortal'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'

interface ActionData {
  section: string
  text: string
}

@Component
export default class MyBillingDetailsCard extends Vue {
  @Action openPortalSection

  actions: ActionData[] = null

  created(): void {
    this.actions = this.getData()
  }

  getData(): ActionData[] {
    return [
      {
        section: SectionType.PortalPaymentMethods,
        text: this.$t('profile.paymentMethods').toString(),
      },
      {
        section: SectionType.PortalBillingHistory,
        text: this.$t('profile.billingHistory').toString(),
      },
      {
        section: SectionType.PortalAddress,
        text: this.$t('profile.billingAddress').toString(),
      },
    ]
  }

  @Watch('$i18n.locale')
  localChange(): void {
    this.actions = this.getData()
  }
}
