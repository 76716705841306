


















import { Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import Component from 'vue-class-component'
import { Server } from '@/types/vpnServerModels'

@Component
export default class ServerFilesDropdown extends Vue {
  @Getter serverList: Server[]
  @Action fetchServerList

  showFiles = false

  created(): void {
    this.fetchServerList()
  }

  toggleShowFiles(): void {
    this.showFiles = !this.showFiles
  }

  openVpnDownload(shortName: string): string {
    return `${process.env.VUE_APP_API_URL}/servers/${shortName}/config.ovpn`
  }
}
