















import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Component
export default class SignUp extends Vue {
  @Getter loading: boolean;
  @Action handleSignup !: (payload: { query: any }) => Promise<void>;
  @Getter signuptoken;

  async mounted(): Promise<void> {
    // It could be that the state is already loaded when returning from redirect
    if (this.loading) {
      const query = this.$route.query
      await this.handleSignup({ query })

      if (this.signuptoken) {
        this.$router.push('/set-password?token=' + this.signuptoken)
      } else {
        this.$router.push('/sign-up')
      }
    }
  }
}
