






























































































import { GooseItemPrice, GooseItemPriceTranslatedMetadata } from '@/types/signUpModels'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'

@Component
export default class LifetimeCard extends Vue {
  @Prop({ required: true })
  items: GooseItemPrice[]

  @Getter deal: GooseItemPrice
  @Getter deals: GooseItemPrice[]
  @Getter currencyCode: string
  @Action switchCurrencyCode;
  @Action selectDeal

  currentItem: GooseItemPrice = null

  created(): void {
    this.currentItem = this.items[0]
  }

  get price(): number {
    return this.currentItem.charges[0]?.price ?? 0
  }

  get meta(): GooseItemPriceTranslatedMetadata {
    return this.currentItem.metadata?.translations?.[this.$i18n.locale] ?? {
      title: undefined,
      productUsp: '-',
      productFeatures: ['Not configured'],
    }
  }

  get isActive(): boolean {
    return this.currentItem.id === this.deal.id
  }

  getTitle(item: GooseItemPrice): string {
    const meta = item.metadata?.translations?.[this.$i18n.locale]
    return meta?.title ?? item.name
  }

  setCurrentItem(item: GooseItemPrice) {
    this.currentItem = item
    this.selectDeal(item)
  }
}
