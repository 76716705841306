
































import { Component } from 'vue-property-decorator'
import Validator from '@/validator'
import { Rules } from 'async-validator'
import { Action, Getter } from 'vuex-class'

type UserInputSchema = {
  email: string;
  password: string;
}

@Component
export default class LoginForm extends Validator<UserInputSchema> {
  model = {
    email: localStorage.getItem('email'),
  } as UserInputSchema;

  rules: Rules = {
    email: { type: 'email', required: true, message: this.$t('errorMessages.invalidEmail').toString() },
    password: { required: true, message: this.$t('errorMessages.passwordMissing').toString() },
  };

  @Getter loginLoading: boolean;
  @Getter microsoftLoginUrl: string;
  @Action login;
  @Action forgotPassword;

  remember = localStorage.getItem('email') != null;

  async handleForgotPassword(): Promise<void> {
    if (this.error.email != null || this.model.email == null) {
      this.$message({ type: 'error', message: this.$t('errorMessages.invalidEmail').toString() })
      return
    }
    await this.forgotPassword(this.model.email)
    this.$message('Password recovery mail send')
  }

  async sendLogin(): Promise<void> {
    if (this.remember) {
      localStorage.setItem('email', this.model.email)
    } else {
      localStorage.removeItem('email')
    }
    if (!(await this._submit())) {
      return
    }
    try {
      await this.login({
        email: this.model.email.toLowerCase(),
        password: this.model.password,
      })
    } catch (e) {
      this.$message.error(this.$t('errorMessages.wrongEmailOrPassword').toString())
      return
    }
    await this.$router.push('/')
  }
}
