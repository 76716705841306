


































import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'

@Component
export default class SignUp extends Vue {
  @Action signupViaActivationCode

  error?: string = null
  code = ''
  email = ''

  get enabled(): boolean {
    return !!this.email && !!this.code
  }

  async submit(): Promise<void> {
    try {
      const token = await this.signupViaActivationCode({ email: this.email, code: this.code })
      this.$router.push('/set-password?token=' + token)
    } catch (error: any) {
      const errorMessages = {
        E1803: this.$t('errorMessages.reusedEmail'),
        E2201: this.$t('errorMessages.invalidCode'),
        E2202: this.$t('errorMessages.reusedCode'),
        E0301: this.$t('errorMessages.invalidEmail'),
      }

      if (error.response.data.code && error.response.data.code in errorMessages) {
        this.error = errorMessages[error.response.data.code]
      } else {
        this.error = error.response.data.message ?? this.$t('errorMessages.unknownError')
      }
    }
  }
}
